<template>
  <div class="bt-transaksi pa-5">
    <h6
      class="text-h5 font-weight-bold orange--text text--lighten-1 d-flex align-center"
    >
      <div
        class="rounded-pill orange lighten-1 me-2 px-2 d-flex align-center justify-center py-2 elevation-3"
      >
        <v-icon small color="white">mdi-clipboard-list</v-icon>
      </div>
      Pengajuan Pembiayaan
    </h6>
    <div class="mt-5 d-flex align-center">
      <div
        class="d-flex align-center orange lighten-1 white--text py-1 px-2 rounded"
      >
        <h5 class="ma-0 me-3">{{ pengajuan.step }}</h5>
        <h5 class="ma-0">{{ pengajuan.titleStep[pengajuan.step] }}</h5>
      </div>
      <div class="d-flex align-center">
        <h5
          v-for="n in 5"
          :key="`ms-${n}`"
          v-show="pengajuan.step <= Number(n)"
          class="orange lighten-1 white--text py-1 px-2 mx-2 rounded"
        >
          {{ Number(n) + 1 }}
        </h5>
      </div>
    </div>
    <v-row class="mt-5" v-show="pengajuan.step == 1">
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Rembug</label>
        <v-select
          color="black"
          v-model="pengajuan.rembug"
          :items="opt.rembug"
          @change="getAnggota()"
          hide-details
          flat
          solo
          dense
          :disabled="$route.params.map_no"
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Anggota</label>
        <v-select
          color="black"
          v-model="pengajuan.anggota"
          :items="opt.anggota"
          @change="setProfile()"
          hide-details
          flat
          solo
          dense
          :disabled="$route.params.map_no"
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-5">
        <label for="">Gunakan MAP Pengajuan Sebelumnya</label>
        <v-select
          color="black"
          v-model="pengajuan.pengajuan"
          :items="opt.pengajuan"
          @change=" setPengajuan()"
          hide-details
          flat
          solo
          dense
          :disabled="$route.params.map_no"
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-5">
        <h4>Data Pribadi</h4>
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">No Anggota</label>
        <v-text-field
          color="black"
          v-model="profile.cif_no"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
          disabled
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Nama Lengkap</label>
        <v-text-field
          color="black"
          v-model="profile.cm_name"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
          disabled
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">No KTP</label>
        <v-text-field
          color="black"
          v-model="profile.id_card"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
          disabled
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Alamat</label>
        <v-textarea
          color="black"
          v-model="profile.address"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
          disabled
          rows="3"
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Rembug</label>
        <v-text-field
          color="black"
          v-model="profile.cm_name"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
          disabled
        />
      </v-col>
      <v-col cols="12" class="py-0 my-5">
        <h4>Data Pengajuan</h4>
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">No Pengajuan</label>
        <v-text-field
          color="black"
          v-model="pengajuan.data.map_no"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
          disabled
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Jenis Pembiayaan</label>
        <v-select
          color="black"
          v-model="pengajuan.data.financing_type"
          :items="opt.financing_type"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Pembiayaan Ke</label>
        <v-text-field
          color="black"
          v-model="pengajuan.data.pyd"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Jumlah Pengajuan</label>
        <vue-numeric
          currency="Rp "
          separator="."
          v-model="pengajuan.data.amount"
          class="white w-100 pa-2"
          :disabled="pengajuan.isEdit"
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Periode</label>
        <v-select
          color="black"
          v-model="pengajuan.data.periode_jangka_waktu"
          :items="opt.periode_jangka_waktu"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Jangka Waktu</label>
        <v-text-field
          color="black"
          v-model="pengajuan.data.jangka_waktu"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Peruntukan</label>
        <v-select
          color="black"
          v-model="pengajuan.data.peruntukan"
          :items="opt.peruntukan"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Keterangan</label>
        <v-textarea
          color="black"
          v-model="pengajuan.data.keterangan"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
          rows="3"
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Sumber Pengembalian</label>
        <v-select
          color="black"
          v-model="pengajuan.data.sumber_pengembalian"
          :items="opt.sumber_pengembalian"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Tanggal Pengajuan</label>
        <v-text-field
          color="black"
          v-model="pengajuan.data.tanggal_pengajuan"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
          type="date"
        />
      </v-col>
      <v-col
        cols="12"
        class="py-0 mb-3"
        description="Tap untuk mengubah gambar"
      >
        <label for="">Upload Ktp</label>
        <label for="fm-ktp" class="w-100" ref="previewImage">
          <img
            :src="
              pengajuan.data.doc_ktp
                ? pengajuan.data.doc_ktp
                : require('@/assets/images/id-front.png')
            "
            fluid
            class="w-100"
          />
          <input
            type="file"
            ref="fm-ktp"
            hidden
            id="fm-ktp"
            @change="previewImage($event, 'ktp')"
            accept="image/*"
          />
        </label>
      </v-col>
      <v-col
        cols="12"
        class="py-0 mb-3"
        description="Tap untuk mengubah gambar"
      >
        <label for="">Upload Kartu Keluarga</label>
        <label for="fm-kk" class="w-100" ref="previewImage">
          <img
            :src="
              pengajuan.data.doc_kk
                ? pengajuan.data.doc_kk
                : require('@/assets/images/id-back.png')
            "
            fluid
            class="w-100"
          />
          <input
            type="file"
            ref="fm-kk"
            hidden
            id="fm-kk"
            @change="previewImage($event, 'kk')"
            accept="image/*"
          />
        </label>
      </v-col>
      <v-col
        cols="12"
        class="py-0 mb-3"
        description="Tap untuk mengubah gambar"
      >
        <label for="">Upload Dokumen Pendukung</label>
        <label for="fm-doc" class="w-100" ref="previewImage">
          <img
            :src="
              pengajuan.data.doc_pendukung
                ? pengajuan.data.doc_pendukung
                : require('@/assets/images/id-back.png')
            "
            fluid
            class="w-100"
          />
          <input
            type="file"
            ref="fm-doc"
            hidden
            id="fm-doc"
            @change="previewImage($event, 'doc')"
            accept="image/*"
          />
        </label>
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Tanda Tangan Nasabah</label>
        <div v-if="$route.params.map_no && pengajuan.data.ttd_anggota && pengajuan.data.ttd_anggota != 'data:image/;base64,'">
          <img
            :src="
              pengajuan.data.ttd_anggota
                ? pengajuan.data.ttd_anggota
                : require('@/assets/images/id-back.png')
            "
            fluid
            class="w-100"
          />
        </div>
        <div v-else>
          <VueSignaturePad
            width="100%"
            height="200px"
            ref="signaturePad1"
            class="white"
          />
          <div class="mt-3">
            <v-btn class="orange lighten-1 white--text" @click="undo(1)" block
              >Ulangi</v-btn
            >
          </div>
        </div>
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Tanda Tangan Pasangan</label>
        <div v-if="$route.params.map_no && pengajuan.data.ttd_pasangan && pengajuan.data.ttd_pasangan != 'data:image/;base64,'">
          <img
            :src="
              pengajuan.data.ttd_pasangan
                ? pengajuan.data.ttd_pasangan
                : require('@/assets/images/id-back.png')
            "
            fluid
            class="w-100"
          />
        </div>
        <div v-else>
          <VueSignaturePad
            width="100%"
            height="200px"
            ref="signaturePad2"
            class="white"
          />
          <div class="mt-3">
            <v-btn class="orange lighten-1 white--text" @click="undo(2)" block
              >Ulangi</v-btn
            >
          </div>
        </div>
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Tanda Tangan TPL</label>
        <div v-if="$route.params.map_no && pengajuan.data.ttd_tpl && pengajuan.data.ttd_tpl != 'data:image/;base64,'">
          <img
            :src="
              pengajuan.data.ttd_tpl
                ? pengajuan.data.ttd_tpl
                : require('@/assets/images/id-back.png')
            "
            fluid
            class="w-100"
          />
        </div>
        <div v-else>
          <VueSignaturePad
            width="100%"
            height="200px"
            ref="signaturePad3"
            class="white"
          />
          <div class="mt-3">
            <v-btn class="orange lighten-1 white--text" @click="undo(1)" block
              >Ulangi</v-btn
            >
          </div>
        </div>
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Tanda Tangan Ketua Rembug</label>
        <div v-if="$route.params.map_no && pengajuan.data.ttd_ketua_majelis && pengajuan.data.ttd_ketua_majelis != 'data:image/;base64,'">
          <img
            :src="
              pengajuan.data.ttd_ketua_majelis
                ? pengajuan.data.ttd_ketua_majelis
                : require('@/assets/images/id-back.png')
            "
            fluid
            class="w-100"
          />
        </div>
        <div v-else>
          <VueSignaturePad
            width="100%"
            height="200px"
            ref="signaturePad4"
            class="white"
          />
          <div class="mt-3">
            <v-btn class="orange lighten-1 white--text" @click="undo(2)" block
              >Ulangi</v-btn
            >
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="mt-5" v-show="pengajuan.step == 2">
      <v-col cols="12" class="py-0 mb-5">
        <h4>Jenis Usaha</h4>
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Jenis Usaha</label>
        <v-text-field
          v-model="pengajuan.data.jenis_usaha"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Komoditi</label>
        <v-text-field
          v-model="pengajuan.data.komoditi"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Lama Usaha (Dalam Tahun)</label>
        <v-text-field
          v-model="pengajuan.data.lama_usaha"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Lokasi Usaha</label>
        <v-text-field
          v-model="pengajuan.data.lokasi_usaha"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Surat Izin Usaha</label>
        <v-text-field
          v-model="pengajuan.data.surat_ijin_usaha"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Aset Usaha</label>
        <v-text-field
          v-model="pengajuan.data.aset_usaha"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Nilai Asset</label>
        <vue-numeric
          currency="Rp "
          separator="."
          v-model="pengajuan.data.nilai_aset"
          autocomplete="false"
          class="white w-100 pa-2"
        />
      </v-col>
    </v-row>
    <v-row class="mt-5" v-show="pengajuan.step == 3">
      <v-col cols="12" class="py-0 mb-5">
        <h4>Modal Usaha</h4>
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Persediaan Awal</label>
        <vue-numeric
          currency="Rp "
          separator="."
          v-model="pengajuan.data.persediaan_awal"
          class="white w-100 pa-2"
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Belanja/ Pembelian</label>
        <vue-numeric
          currency="Rp "
          separator="."
          v-model="pengajuan.data.belanja_pembelian"
          class="white w-100 pa-2"
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Persediaan Akhir</label>
        <vue-numeric
          currency="Rp "
          separator="."
          v-model="pengajuan.data.persediaan_akhir"
          class="white w-100 pa-2"
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">HPP</label>
        <vue-numeric
          currency="Rp "
          separator="."
          :value="
            Number(pengajuan.data.belanja_pembelian) +
            Number(pengajuan.data.persediaan_awal) -
            Number(pengajuan.data.persediaan_akhir)
          "
          disabled
          class="white w-100 pa-2"
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Omset</label>
        <vue-numeric
          currency="Rp "
          separator="."
          v-model="pengajuan.data.omset"
          class="white w-100 pa-2"
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Laba Kotor</label>
        <vue-numeric
          currency="Rp "
          separator="."
          :value="
            Number(pengajuan.data.omset) -
            Number(pengajuan.data.belanja_pembelian) +
            Number(pengajuan.data.persediaan_awal) -
            Number(pengajuan.data.persediaan_akhir)
          "
          disabled
          class="white w-100 pa-2"
        />
      </v-col>
      <v-col cols="12" class="py-0 my-5">
        <h4>Biaya Usaha</h4>
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Piutang</label>
        <vue-numeric
          currency="Rp "
          separator="."
          v-model="pengajuan.data.piutang"
          class="white w-100 pa-2"
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Biaya Usaha</label>
        <vue-numeric
          currency="Rp "
          separator="."
          v-model="pengajuan.data.biaya_usaha"
          class="white w-100 pa-2"
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Sewa Tempat</label>
        <vue-numeric
          currency="Rp "
          separator="."
          v-model="pengajuan.data.sewa_tempat"
          class="white w-100 pa-2"
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Total Biaya Usaha</label>
        <vue-numeric
          currency="Rp "
          separator="."
          :value="
            Number(pengajuan.data.piutang) +
            Number(pengajuan.data.biaya_usaha) +
            Number(pengajuan.data.sewa_tempat)
          "
          disabled
          class="white w-100 pa-2"
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Keuntungan Usaha</label>
        <vue-numeric
          currency="Rp "
          separator="."
          :value="
            Number(pengajuan.data.omset) -
            Number(pengajuan.data.belanja_pembelian) +
            Number(pengajuan.data.persediaan_awal) -
            Number(pengajuan.data.persediaan_akhir) -
            (Number(pengajuan.data.piutang) +
              Number(pengajuan.data.biaya_usaha) +
              Number(pengajuan.data.sewa_tempat))
          "
          disabled
          class="white w-100 pa-2"
        />
      </v-col>
    </v-row>
    <v-row class="mt-5" v-show="pengajuan.step == 4">
      <v-col cols="12" class="py-0 mb-5">
        <h4>Data Anggota</h4>
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">No Telp Anggota</label>
        <v-text-field
          v-model="pengajuan.data.telepon_anggota"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Pekerjaan Anggota</label>
        <v-select
          v-model="pengajuan.data.pekerjaan_anggota"
          :items="(pengajuan.data.sumber_pengembalian == 'gaji' || pengajuan.data.sumber_pengembalian == 'non usaha') ? opt.pekerjaan_1 : opt.pekerjaan_2"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Keterangan Pekerjaan</label>
        <v-textarea
          v-model="pengajuan.data.keterangan_pekerjaan_anggota"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
          rows="2"
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">No Telp Pasangan</label>
        <v-text-field
          v-model="pengajuan.data.telepon_pasangan"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Pekerjaan Pasangan</label>
        <v-select
          v-model="pengajuan.data.pekerjaan_pasangan"
          :items="opt.pekerjaan"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Keterangan Pekerjaan Pasangan</label>
        <v-textarea
          v-model="pengajuan.data.keterangan_pekerjaan_pasangan"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
          rows="2"
        />
      </v-col>
      <v-col cols="12" class="py-0 my-5">
        <h4>Pendapatan / Penghasilan</h4>
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Pendapatan Gaji</label>
        <vue-numeric
          currency="Rp "
          separator="."
          v-model="pengajuan.data.pendapatan_gaji"
          class="white w-100 pa-2"
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Pendapatan Usaha</label>
        <vue-numeric
          currency="Rp "
          separator="."
          v-model="pengajuan.data.pendapatan_usaha"
          class="white w-100 pa-2"
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Pendapatan Lainnya</label>
        <vue-numeric
          currency="Rp "
          separator="."
          v-model="pengajuan.data.pendapatan_lainnya"
          class="white w-100 pa-2"
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Total Pendapatan</label>
        <vue-numeric
          currency="Rp "
          separator="."
          :value="
            Number(pengajuan.data.pendapatan_gaji) +
            Number(pengajuan.data.pendapatan_usaha) +
            Number(pengajuan.data.pendapatan_lainnya)
          "
          disabled
          class="white w-100 pa-2"
        />
      </v-col>
    </v-row>
    <v-row class="mt-5" v-show="pengajuan.step == 5">
      <v-col cols="12" class="py-0 mb-5">
        <h4>Tanggungan dan Saving Power</h4>
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Jumlah Tanggungan</label>
        <v-text-field
          v-model="pengajuan.data.jumlah_tanggungan"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Biaya Rumah Tangga</label>
        <vue-numeric
          currency="Rp "
          separator="."
          v-model="pengajuan.data.biaya_rumah_tangga"
          class="white w-100 pa-2"
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Biaya Rekening</label>
        <vue-numeric
          currency="Rp "
          separator="."
          v-model="pengajuan.data.biaya_rekening"
          class="white w-100 pa-2"
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Biaya Kontrakan</label>
        <vue-numeric
          currency="Rp "
          separator="."
          v-model="pengajuan.data.biaya_kontrakan"
          class="white w-100 pa-2"
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Biaya Pendidikan</label>
        <vue-numeric
          currency="Rp "
          separator="."
          v-model="pengajuan.data.biaya_pendidikan"
          class="white w-100 pa-2"
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Hutang Lainnya</label>
        <vue-numeric
          currency="Rp "
          separator="."
          v-model="pengajuan.data.hutang_lainnya"
          class="white w-100 pa-2"
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Total Biaya</label>
        <vue-numeric
          currency="Rp "
          separator="."
          :value="
            Number(pengajuan.data.biaya_rumah_tangga) +
            Number(pengajuan.data.biaya_rekening) +
            Number(pengajuan.data.biaya_kontrakan) +
            Number(pengajuan.data.biaya_pendidikan) +
            Number(pengajuan.data.hutang_lainnya)
          "
          disabled
          class="white w-100 pa-2"
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Saving Power</label>
        <vue-numeric
          currency="Rp "
          separator="."
          :value="
            Number(pengajuan.data.pendapatan_gaji) +
            Number(pengajuan.data.pendapatan_usaha) +
            Number(pengajuan.data.pendapatan_lainnya) -
            (Number(pengajuan.data.biaya_rumah_tangga) +
              Number(pengajuan.data.biaya_rekening) +
              Number(pengajuan.data.biaya_kontrakan) +
              Number(pengajuan.data.biaya_pendidikan) +
              Number(pengajuan.data.hutang_lainnya))
          "
          disabled
          class="white w-100 pa-2"
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Repayment Capacity</label>
        <vue-numeric
          currency="Rp "
          separator="."
          :value="
            ((Number(pengajuan.data.pendapatan_gaji) +
              Number(pengajuan.data.pendapatan_usaha) +
              Number(pengajuan.data.pendapatan_lainnya) -
              (Number(pengajuan.data.biaya_rumah_tangga) +
                Number(pengajuan.data.biaya_rekening) +
                Number(pengajuan.data.biaya_kontrakan) +
                Number(pengajuan.data.biaya_pendidikan) +
                Number(pengajuan.data.hutang_lainnya))) *
              75) /
            100
          "
          disabled
          class="white w-100 pa-2"
        />
      </v-col>
    </v-row>
    <v-row class="mt-5" v-show="pengajuan.step == 6">
      <v-col cols="12" class="py-0 mb-5">
        <h4>Prestasi Anggota</h4>
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Plafon Sebelumnya</label>
        <vue-numeric
          currency="Rp "
          separator="."
          v-model="pengajuan.prestasi.plafon_sebelumnya"
          class="white w-100 pa-2"
          disabled
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Prestasi Angsuran</label>
        <v-text-field
          v-model="pengajuan.prestasi.prestasi_angsuran"
          hide-details
          flat
          solo
          dense
          disabled
        />
      </v-col>
      <v-col cols="12" class="py-0 my-3">
        <h4>Rataan Tabungan</h4>
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Total Setoran</label>
        <vue-numeric
          currency="Rp "
          separator="."
          v-model="pengajuan.prestasi.total_setoran"
          class="white w-100 pa-2"
          disabled
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Total Penarikan</label>
        <vue-numeric
          currency="Rp "
          separator="."
          v-model="pengajuan.prestasi.total_penarikan"
          class="white w-100 pa-2"
          disabled
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Rataan Setoran</label>
        <vue-numeric
          currency="Rp "
          separator="."
          v-model="pengajuan.prestasi.rataan_setoran"
          class="white w-100 pa-2"
          disabled
        />
      </v-col>
      <v-col cols="12" class="py-0 my-3">
        <h4>Saldo Tabungan</h4>
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Saldo Sukarela</label>
        <vue-numeric
          currency="Rp "
          separator="."
          v-model="pengajuan.prestasi.saldo_sukarela"
          class="white w-100 pa-2"
          disabled
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Saldo Taber</label>
        <vue-numeric
          currency="Rp "
          separator="."
          v-model="pengajuan.prestasi.saldo_taber"
          class="white w-100 pa-2"
          disabled
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Lama Majelis</label>
        <v-text-field
          v-model="pengajuan.prestasi.lama_majelis"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Kehadiran Anggota</label>
        <v-text-field
          v-model="pengajuan.prestasi.kehadiran_anggota"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Rataan Kehadiran Majelis</label>
        <v-text-field
          v-model="pengajuan.prestasi.prestasi_angsuran"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Pernah Tanggung Renteng</label>
        <v-select
          color="black"
          v-model="pengajuan.prestasi.tanggung_renteng"
          :items="opt.tanggung_renteng"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Kekompakan Dalam Majelis</label>
        <v-select
          color="black"
          v-model="pengajuan.prestasi.kekompakan"
          :items="opt.kekompakan"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
    </v-row>
    <div class="d-flex justify-center mt-4 pt-4">
      <v-btn
        class="orange lighten-1 white--text mx-2"
        @click="move(pengajuan.step - 1)"
        v-show="pengajuan.step > 1"
        >Kembali</v-btn
      >
      <v-btn
        class="orange lighten-1 white--text mx-2"
        @click="move(pengajuan.step + 1)"
        :disabled="pengajuan.loading"
      >
        {{ pengajuan.step == 6 ? "Buat Pengajuan" : "Selanjutnya" }}
      </v-btn>
    </div>
    <v-row class="mt-3">
      <v-col cols="12">
        <v-btn
          class="orange lighten-1 white--text"
          @click="doPengajuan()"
          :disabled="pengajuan.loading"
          block
        >
          Simpan
        </v-btn>
      </v-col>
    </v-row>
    <v-snackbar v-model="alert.show" :timeout="3000">
      {{ alert.msg }}
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import services from "@/services";
import helper from "@/utils/helper"
export default {
  name: "KeangotaanMasuk",
  components: {
  },
  data() {
    return {
      profile: Object,
      pengajuan: {
        data: {
          cif_no: null,
          amount: 0,
          financing_type: null,
          peruntukan: null,
          rencana_droping: null,
          pembiayaan_ke: 1,
          periode_jangka_waktu: 1,
          jangka_waktu: 0,
          description: null,
          tanggal_pengajuan: null,
          created_at: null,
          created_by: null,
          pyd: 1,
          map_no: null,
          ttd_anggota: null,
          ttd_pasangan: null,
          ttd_tpl: null,
          ttd_ketua_rembug: null,
          sumber_pengembalian: null,
          jenis_usaha: "",
          komoditi: "",
          lama_usaha: "",
          lokasi_usaha: "",
          surat_ijin_usaha: "",
          aset_usaha: "",
          nilai_aset: 0,
          persediaan_awal: 0,
          belanja_pembelian: 0,
          persediaan_akhir: 0,
          hpp: 0,
          omset: 0,
          laba_kotor: 0,
          piutang: 0,
          biaya_usaha: 0,
          sewa_tempat: 0,
          total_biaya_usaha: 0,
          keuntungan_usaha: 0,
          telepon_anggota: null,
          pekerjaan_anggota: null,
          keterangan_pekerjaan_anggota: null,
          telepon_pasangan: null,
          pekerjaan_pasangan: null,
          keterangan_pekerjaan_pasangan: null,
          pendapatan_gaji: 0,
          pendapatan_usaha: 0,
          pendapatan_lainnya: 0,
          total_pendapatan: 0,
          jumlah_tanggungan: null,
          biaya_rumah_tangga: 0,
          biaya_rekening: 0,
          biaya_kontrakan: 0,
          biaya_pendidikan: 0,
          hutang_lainnya: 0,
          total_biaya: 0,
          saving_power: 0,
          repayment_capacity: 0,
          doc_ktp: null,
          doc_kk: null,
          doc_pendukung: null,
          token: null,
        },
        titleStep: [
          "",
          "Data Pribadi & Data Pengajuan",
          "Memorandum Analisis Pembiayaan 1",
          "Memorandum Analisis Pembiayaan 2",
          "Memorandum Analisis Pembiayaan 3",
          "Memorandum Analisis Pembiayaan 4",
          "Prestasi",
        ],
        step: 1,
        maxstep: 6,
        title: [],
        loading: false,
        rembug: null,
        anggota: null,
        pengajuan: null,
        prestasi: Object,
        isEdit: false
      },
      opt: {
        financing_type: [
          { value: 0, text: "Kelompok" },
          { value: 1, text: "Individu" },
        ],
        peruntukan: [
          { value: 1, text: "Modal kerja" },
          { value: 2, text: "Investasi" },
          { value: 3, text: "Pendidikan" },
          { value: 4, text: "Perumahan" },
          { value: 5, text: "Kesehatan" },
          { value: 6, text: "Aset" },
          { value: 9, text: "Lain-Lain" },
          { value: 7, text: "Air bersih & Sanitasi" },
        ],
        sumber_pengembalian: ["sumber usaha", "gaji", "non usaha"],
        pekerjaan: [
          { value: 0, text: "Ibu Rumah Tangga" },
          { value: 1, text: "Buruh" },
          { value: 2, text: "Petani" },
          { value: 3, text: "Pedagang" },
          { value: 4, text: "Wiraswasta" },
          { value: 5, text: "Pegawai Negeri Sipil" },
          { value: 6, text: "Karyawan Swasta" },
        ],
        pekerjaan_1: [
          { value: 1, text: "Buruh" },
          { value: 5, text: "Pegawai Negeri Sipil" },
          { value: 6, text: "Karyawan Swasta" },
        ],
        pekerjaan_2: [
          { value: 0, text: "Ibu Rumah Tangga" },
          { value: 2, text: "Petani" },
          { value: 3, text: "Pedagang" },
          { value: 4, text: "Wiraswasta" },
        ],
        periode_jangka_waktu: [
          { value: 1, text: "Mingguan" },
          { value: 2, text: "Bulanan" },
          { value: 3, text: "Jatuh Tempo" },
        ],
        rembug: [],
        anggota: [],
        pengajuan: [],
        kekompakan: ["kompak","tidak kompak"],
        tanggung_renteng: ["belum","pernah"]
      },
      alert: {
        show: false,
        msg: null,
      },
    };
  },
  computed: {
    ...mapGetters(["user","pembiayaan"]),
  },
  methods: {
    ...helper,
    ...mapActions(['savePembiayaan']),
    async getRembug() {
      let day = new Date().getDay();
      let payload = new FormData();
      payload.append("fa_code", this.user.fa_code);
      payload.append("day", day);
      // payload.append("day", 1);
      try {
        let req = await services.infoRembug(payload, this.user.token);
        if (req.status === 200) {
          let { data } = req.data;
          this.opt.rembug = [];
          data.map((item) => {
            let opt = {
              value: item.cm_code,
              text: item.cm_name,
              data: item,
            };
            this.opt.rembug.push(opt);
          });
        } else {
          this.alert = {
            show: true,
            msg: 'Gagal mendapatkan data Rembug',
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
    },
    async getAnggota() {
      this.opt.anggota = [];
      let payload = new FormData();
      if(this.$route.params.map_no){
        this.pengajuan.rembug = this.$route.params.cm_code
      }
      payload.append("cm_code", this.pengajuan.rembug);
      try {
        let req = await services.infoMember(payload, this.user.token);
        if (req.status === 200) {
          let { data } = req.data;
          data.map((item) => {
            let opt = {
              value: item.cif_no,
              text: item.nama,
              data: item,
            };
            this.opt.anggota.push(opt);
          });
          if(this.$route.params.map_no){
            this.setProfile()
          }
        } else {
          this.alert = {
            show: true,
            msg: 'Gagal mendapatkan data Anggota',
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
    },
    async getPengajuan() {
      this.opt.pengajuan = [];
      let payload = new FormData();
      if(this.$route.params.map_no){
        this.pengajuan.anggota = this.$route.params.cif_no
      }
      payload.append("cif_no", this.pengajuan.anggota);
      try {
        let req = await services.pengajuanAnggota(payload, this.user.token);
        let { data, status } = req.data;
        if (req.status === 200 && status) {
          data.map((item) => {
            let opt = {
              value: item.registration_no,
              text: `${item.registration_no} - Rp ${this.thousand(item.amount)}`,
              data: item,
            };
            this.opt.pengajuan.push(opt);
          });
          this.pengajuan.pengajuan = data[0].registration_no
          this.getDetailPengajuan()
          this.pengajuan.data.pyd = data.length + 1
        } else {
          this.alert = {
            show: true,
            msg: 'Anggota belum memiliki pembiayaan',
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
    },
    async getDetailPengajuan() {
      if(this.$route.params.map_no){
        this.pengajuan.pengajuan = this.$route.params.map_no
      }
      let payload = `registration_no=${this.pengajuan.pengajuan}`;
      try {
        let req = await services.pengajuanDetail(payload, this.user.token);
        let { data, status } = req.data;
        if (req.status === 200 && status) {
          this.pengajuan.data = {...data}
          this.pengajuan.data.financing_type = Number(this.pengajuan.data.financing_type)
          this.pengajuan.data.periode_jangka_waktu = Number(this.pengajuan.data.periode_jangka_waktu)
          this.pengajuan.data.peruntukan = Number(this.pengajuan.data.peruntukan)
          this.pengajuan.data.keterangan = this.pengajuan.data.description
          this.pengajuan.data.sumber_pengembalian = this.pengajuan.data.sumber_pengembalian
          this.pengajuan.data.tanggal_pengajuan = this.defaultDate(this.pengajuan.data.tanggal_pengajuan)
          if(this.pengajuan.data.doc_ktp == 'data:image/;base64,' || !this.pengajuan.data.doc_ktp){
            this.pengajuan.data.doc_ktp = null
          }
          if(this.pengajuan.data.doc_kk == 'data:image/;base64,' || !this.pengajuan.data.doc_kk){
            this.pengajuan.data.doc_kk = null
          }
          if(this.pengajuan.data.doc_pendukung == 'data:image/;base64,' || !this.pengajuan.data.doc_pendukung){
            this.pengajuan.data.doc_pendukung = null
          }
        } else {
          this.alert = {
            show: true,
            msg: 'Gagal mendapatkan detil pembiayaan',
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
    },
    setProfile() {
      if(this.$route.params.map_no){
        this.pengajuan.anggota = this.$route.params.cif_no
      }
      let profile = this.opt.anggota.find(
        (item) => item.value == this.pengajuan.anggota
      ).data;
      this.profile = profile;
      if(!this.$route.params.map_no){
        this.generateDate()
      }
      this.getPengajuan()
    },
    setPengajuan() {
      this.getDetailPengajuan()
    },
    async doPengajuan() {
      // prepare the data
      var dateObj = new Date();
      dateObj.setDate(dateObj.getDate() + 3);
      var month = String(dateObj.getUTCMonth() + 1).padStart(2, "0");
      var day = String(dateObj.getUTCDate()).padStart(2, "0");
      var year = dateObj.getUTCFullYear();
      
      this.pengajuan.data.cif_no = this.profile.cif_no;
      this.pengajuan.data.created_by = this.profile.cif_no;
      this.pengajuan.data.token = this.user.token;
      this.pengajuan.data.rencana_droping = `${day}/${month}/${year}`;

      const { isEmpty, data } = this.$refs.signaturePad1.saveSignature();
      const sig2 = this.$refs.signaturePad2.saveSignature();
      const isEmpty2 = sig2.isEmpty;
      const data2 = sig2.data;
      const sig3 = this.$refs.signaturePad3.saveSignature();
      const isEmpty3 = sig3.isEmpty;
      const data3 = sig3.data;
      const sig4 = this.$refs.signaturePad4.saveSignature();
      const isEmpty4 = sig4.isEmpty;
      const data4 = sig4.data;
      if (!isEmpty) this.pengajuan.data.ttd_anggota = data;
      if (!isEmpty2) this.pengajuan.data.ttd_pasangan = data2;
      if (!isEmpty3) this.pengajuan.data.ttd_tpl = data3;
      if (!isEmpty4) this.pengajuan.data.ttd_ketua_rembug = data4;
      this.pengajuan.data.created_at = new Date()

      let payload = new FormData()
      let payloadData = this.pengajuan.data
      for(let key in payloadData){
        payload.append(key,payloadData[key])
      }
      this.pengajuan.loading = true
      this.savePembiayaan(this.pengajuan.data)
      try {
        let req
        if(this.pengajuan.isEdit){
          req = await services.updatePangajuanPembiayaan(payload, this.user.token);
        } else {
          req = await services.transPembiayaanPengajuan(payload, this.user.token);
        }
        let { data, status, msg } = req.data
        if (req.status === 200 && status) {
          this.alert = {
            show: true,
            msg: 'Pengajuan Pembiayaan berhasil dibuat',
          };
          setTimeout(() => {
            this.$router.push(`/pembiayaan`);
          },1000)
        } else {
          this.alert = {
            show: true,
            msg: 'Pengajuan Pembiayaan gagal dibuat',
          };
        }
        this.pengajuan.loading = false
      } catch (error) {
        this.pengajuan.loading = false
        this.alert = {
          show: true,
          msg: error,
        };
      }
    },
    move(step) {
      var dateObj = new Date();
      dateObj.setDate(dateObj.getDate() + 3);
      var month = String(dateObj.getUTCMonth() + 1).padStart(2, "0");
      var day = String(dateObj.getUTCDate()).padStart(2, "0");
      var year = dateObj.getUTCFullYear();
      let lanjut = true;
      if (step > 0 && step <= this.pengajuan.maxstep) {
        if (Number(this.pengajuan.data.jangka_waktu) < 1) {
          this.notif("Warning", "Jangka waktu harus diisi", "warning");
          lanjut = false;
        } else {
          lanjut = true;
        }
        if (lanjut) {
          if (this.pengajuan.data.sumber_pengembalian == "gaji" || this.pengajuan.data.sumber_pengembalian == "non usaha") {
            if (step == 2) {
              this.pengajuan.step = 4;
            } else if (step == 3) {
              this.pengajuan.step = 1;
            } else {
              this.pengajuan.step = step;
            }
          } else {
            this.pengajuan.step = step;
          }
        }
        this.scrollToTop();
      }
      let hpp =
        Number(this.pengajuan.data.belanja_pembelian) +
        Number(this.pengajuan.data.persediaan_awal) -
        Number(this.pengajuan.data.persediaan_akhir);
      let laba_kotor = Number(this.pengajuan.data.omset) - hpp;
      let total_biaya_usaha =
        Number(this.pengajuan.data.piutang) +
        Number(this.pengajuan.data.biaya_usaha) +
        Number(this.pengajuan.data.sewa_tempat);
      let keuntungan_usaha = laba_kotor - total_biaya_usaha;
      let total_pendapatan =
        Number(this.pengajuan.data.pendapatan_gaji) +
        Number(this.pengajuan.data.pendapatan_usaha) +
        Number(this.pengajuan.data.pendapatan_lainnya);
      let total_biaya =
        Number(this.pengajuan.data.biaya_rumah_tangga) +
        Number(this.pengajuan.data.biaya_rekening) +
        Number(this.pengajuan.data.biaya_kontrakan) +
        Number(this.pengajuan.data.biaya_pendidikan) +
        Number(this.pengajuan.data.hutang_lainnya);
      let saving_power = total_pendapatan - total_biaya;
      let repayment_capacity = (saving_power * 75) / 100;
      this.pengajuan.data.hpp = hpp;
      this.pengajuan.data.laba_kotor = laba_kotor;
      this.pengajuan.data.total_biaya_usaha = total_biaya_usaha;
      this.pengajuan.data.keuntungan_usaha = keuntungan_usaha;
      this.pengajuan.data.total_pendapatan = total_pendapatan;
      this.pengajuan.data.total_biaya = total_biaya;
      this.pengajuan.data.saving_power = saving_power;
      this.pengajuan.data.repayment_capacity = repayment_capacity;
      if (step > 6) {
        this.doPengajuan();
      }
    },
    generateDate(){
      console.log("generate date")
      var dateObj = new Date();
      var month = String(dateObj.getUTCMonth() + 1).padStart(2, '0')
      var day = String(dateObj.getUTCDate()).padStart(2, '0')
      var year = dateObj.getUTCFullYear();
      var hours = dateObj.getHours()
      var minutes = dateObj.getMinutes() 
      var seconds = dateObj.getSeconds()
      this.pengajuan.data.tanggal_pengajuan = `${year}-${month}-${day}`
      console.log(`${this.profile.cif_no}${year}${month}${day}${hours}${minutes}${seconds}`)
      this.pengajuan.data.map_no = `${this.profile.cif_no}${year}${month}${day}${hours}${minutes}${seconds}`
    },
    previewImage(event, target) {
      let theImg = null;
      let vm = this;
      const ktp = this.$refs["fm-ktp"];
      const kk = this.$refs["fm-kk"];
      const doc = this.$refs["fm-doc"];
      let reader = new FileReader();
      switch (target) {
        case "ktp":
          theImg = event.target.files[0];
          reader.readAsDataURL(theImg);
          reader.onload = function () {
            vm.pengajuan.data.doc_ktp = reader.result;
            ktp.type = "text";
            ktp.type = "file";
          };
          reader.onerror = function () {
            vm.pengajuan.data.doc_ktp = null;
            ktp.type = "text";
            ktp.type = "file";
          };
          break;
        case "kk":
          theImg = event.target.files[0];
          reader.readAsDataURL(theImg);
          reader.onload = function () {
            vm.pengajuan.data.doc_kk = reader.result;
            kk.type = "text";
            kk.type = "file";
          };
          reader.onerror = function () {
            vm.pengajuan.data.doc_kk = null;
            kk.type = "text";
            kk.type = "file";
          };
          break;
        case "doc":
          theImg = event.target.files[0];
          reader.readAsDataURL(theImg);
          reader.onload = function () {
            vm.pengajuan.data.doc_pendukung = reader.result;
            doc.type = "text";
            doc.type = "file";
          };
          reader.onerror = function () {
            vm.pengajuan.data.doc_pendukung = null;
            doc.type = "text";
            doc.type = "file";
          };
          break;
      }
    },
    undo(idx) {
      this.$refs['signaturePad'+idx].undoSignature();
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    notif(title, msg, type) {
      this.alert = {
        show: true,
        msg: msg,
      };
    },
  },
  mounted() {
    this.getRembug();
    if(this.$route.params.map_no){
      this.pengajuan.isEdit = true
      this.getAnggota()
      this.getDetailPengajuan()
    }
  },
};
</script>
